@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOrderDetailsItems";

#{$selector} {
  &__listitem {
    display: flex;
    flex-direction: column;

    &__button {
      width: 100%;
    }

    &__content {
      display: flex;
      width: 100%;
      min-height: 100px;

      @include screenSmallerThan($screen-md) {
        flex-direction: column;
      }

      &__image {
        position: relative;
        display: flex;
        width: 20%;
        min-width: 150px;
        margin-right: $space-xl;
        padding: $space-md;

        @include screenSmallerThan($screen-md) {
          width: 100%;
        }

        & > * {
          position: relative !important;
          width: 100px !important;
          height: 100px !important;
          margin-right: $space-md;
        }
      }

      &__detail {
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: space-between;
        width: 80%;
        padding: $space-md;

        @include screenSmallerThan($screen-md) {
          width: 100%;
        }

        &__name {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: space-between;

          @include screenSmallerThan($screen-md) {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        &__name:nth-child(1) {
          @include text("meta");
          font-weight: bold;
        }

        &__attributes {
          display: flex;
          flex-direction: column;
          gap: 8px;

          &__attribute {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: small;

            & > *:nth-child(1) {
              font-weight: bold;
            }
          }
        }

        &__price {
          @include text("meta");
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &__divider {
    width: 100%;
    margin: 0 !important;
  }

  &__total {
    @include text("meta");
    display: flex;
    justify-content: flex-end;
    padding: calc($space-md + 16px);
    font-weight: bold;
  }
}
